body {
  margin: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.p-component {
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

.layout-content {
  min-height: 88.75vh;
  padding: 3rem;
}

.field {
  padding-top: 1rem;
}

.status-INSTOCK {
  background: #c8e6c9;
  color: #256029;
}

.status-OUTOFSTOCK {
  background: #ffcdd2;
  color: #c63737;
}
.availableProducts .p-dropdown {
  width: 14rem;
  font-weight: normal;
}

.product-name {
  font-size: 1.5rem;
  font-weight: 700;
}

.availableProducts .product-description {
  margin: 0 0 2rem 0;
}

.product-shipping-icon {
  vertical-align: middle;
  margin-right: .5rem;
}

.shipping-info {
  font-weight: 600;
  vertical-align: middle;
}

.availableProducts .product-list-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
  height: 100%;
}

.product-img-thumbnail {
  width: 10rem;
  height: 10rem;
  padding: .5rem;
  /*box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);*/
  border: .1rem solid lightgray;
  margin-right: 2rem;
}

.availableProducts .product-list-item .product-list-detail {
  flex: 1 1 0;
  height: 100%;
  margin-right: .4rem;
  position: relative;
}

.availableProducts .product-list-item .p-rating {
  margin: 0 0 .5rem 0;
}

.availableProducts .product-list-item .product-price {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: .5rem;
  align-self: flex-end;
}

.availableProducts .product-list-item .product-list-action {
  display: flex;
  flex-direction: column;
}

.availableProducts .product-grid-item {
  margin: .5em;
  border: 1px solid #dee2e6;
  padding: 2rem;
}

.availableProducts .product-grid-item .product-grid-item-top,
  .availableProducts .product-grid-item .product-grid-item-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.availableProducts .product-grid-item img {
  width: 75%;
  height: 75%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin: 2rem 0;
}

.availableProducts .product-grid-item .product-grid-item-content {
  text-align: center;
}

.availableProducts .product-grid-item .product-price {
  font-size: 1.5rem;
  font-weight: 600;
}

.p-menubar {
  padding: 1rem !important;
}

.form-container * .field {
  margin-bottom: 2rem;
}

@media screen and (max-width: 576px) {
  .availableProducts .product-list-item {
      flex-direction: column;
      align-items: center;
  }

  .layout-content {
    padding: 0rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }

  .availableProducts .product-list-item img {
      width: 10rem;
      height: 10rem;
      margin: 2rem 0;
  }

  .availableProducts .product-list-item .product-price {
      align-self: center;
  }

  .availableProducts .product-list-item .product-list-action {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: .5rem;
  }


}